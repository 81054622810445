import React, { useState, useEffect } from "react"
import styled from "styled-components"

// import material ui
import { Typography, TextField, MenuItem, Select, FormControl, InputLabel, Grid, Divider } from "@material-ui/core"

// import app components
import Button from "components/button"

export default function DeckingCalculator(props) {
  const initialState = {
    squareFootage: "",
    nominalSize: ""
  }
  const [values, setValues] = useState(initialState)

  const { squareFootage, nominalSize } = values

  const [total, setTotal] = useState(0)

  // calculate total
  useEffect(() => {
    values.nominalSize !== "" &&
      values.squareFootage !== "" &&
      setTotal(
        Math.round((squareFootage * nominalSize + ((squareFootage * nominalSize) / 50) * 4.06) * 1.1 * 100) / 100
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const handleChange = key => event => {
    setValues({ ...values, [key]: event.target.value })
  }

  const resetValues = () => {
    setValues(initialState)
    setTotal(0)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography id="squareFootage" children="1. Enter the full square footage of the deck you wish to cover:" />
        <StyledTextField
          label="Square Footage"
          aria-labelledby="squareFootage"
          value={values.squareFootage}
          onChange={handleChange("squareFootage")}
          variant="filled"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography id="sizeSelect" children="2. Select the nominal size of the decking:" />
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="filled-size-simple">Size</InputLabel>

          <Select
            aria-labelledby="sizeSelect"
            value={values.nominalSize}
            onChange={handleChange("nominalSize")}
          >
            <MenuItem value={0}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={3}>1 – 1/4 x 4</MenuItem>
            <MenuItem value={3}>2 x 4</MenuItem>
            <MenuItem value={2}>1 – 1/4 x 6</MenuItem>
            <MenuItem value={2}>2 x 6</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Result
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Total"
              value={total || 0}
              onChange={() => { }}
              variant="filled"
              fullWidth
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="caption">10% is added to accomodate trim</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controls>
          <StyledButton onClick={() => resetValues()}>Reset</StyledButton>
        </Controls>
      </Grid>
    </Grid>
  )
}

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`
const Controls = styled.div`
  display: flex;
`
const StyledButton = styled(Button)`
  margin-left: 0;
  margin-right: 20px;
`
