import React, { useState } from "react"
import styled from "styled-components"

// import material ui
import { Typography } from "@material-ui/core"
import { Magnify } from "mdi-material-ui"

// import app components
import Link from "components/link"
import BackgroundImage from "components/backgroundImage"
import Button from "components/button"
import Collapse from "@material-ui/core/Collapse"
import UpIcon from "../../../static/images/icons/up-accordion.svg"

import { getUrlPath } from "utils/getUrlPath"
import * as theme from "theme"
import { useStore } from "store"

const FaqAccordion = (props) => {
  const { headline, boxes } = props
  const [activeIndex, setActiveIndex] = useState(null)

  // const handleClick = (id) => {
  //   if (isIn && isIn === id) {
  //     console.log("first")
  //     setIsIn(10000)
  //   }
  //   setIsIn(id)
  // }

  const dispatch = useStore()[1]

  const showLightBox = (link, image) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: 0,
        slides: [{ link, image }],
        options: {
          thumbs: false
        }
      }
    })
  }

  const LinkWrapper = (url, el, props) => {
    if (!url) {
      return el
    }

    if (url.includes("youtu")) {
      // youtube
      // eslint-disable-next-line
      return <div onClick={() => showLightBox({ url }, props.image)} children={el} style={{ cursor: "pointer" }} />
    } else if (getUrlPath(url).includes("http")) {
      // external link
      return <a href={url} children={el} aria-label="External link" />
    } else {
      // internal link
      return <Link to={getUrlPath(url)} children={el} />
    }
  }
  let imageObject = "image"

  return (
    <Container>
      {!!headline && (
        <MainHeadline>
          <Typography variant="h2" children={headline} gutterBottom />
        </MainHeadline>
      )}
      {!!boxes && (
        <>
          {boxes.map(({ link, text, title, link2 }, index) => {
            return (
              <Box key={index} hideSidebar={true} hideButton={false}>
                {title && (
                  <Headline
                    onClick={() => {
                      setActiveIndex(index === activeIndex ? false : index)
                    }}
                    color="primary"
                    variant="h4"
                    component="h4"
                  >
                    {title}
                    <IconContainer show={activeIndex === index}>
                      <UpIcon />
                    </IconContainer>
                  </Headline>
                )}

                <HideSection show={activeIndex === index}>
                  {text && (
                    <Text>
                      <Typography>{text}</Typography>
                    </Text>
                  )}
                  {!false &&
                    link &&
                    LinkWrapper(
                      link.url,
                      <ButtonContainer>
                        <Button children={link?.title ? link.title : "WATCH VIDEO"} />
                      </ButtonContainer>,
                      { image: imageObject }
                    )}
                  {link2?.title && link2?.url && (
                    <ButtonContainer>
                      <Button to={link2?.url} children={link2?.title} />
                    </ButtonContainer>
                  )}
                </HideSection>
              </Box>
            )
          })}
        </>
      )}
    </Container>
  )
}

export default FaqAccordion

const Container = styled.div`
  margin: 20px 10px;
`
const MainHeadline = styled.div`
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 15px;
  margin-top: 50px;
`

const Box = styled.div`
  position: relative;
  padding-top: 32px;
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px solid #e5e7eb;

  &:last-of-type {
    margin-right: 0;
  }
  a {
    text-decoration: none;
  }
`

const ButtonContainer = styled.div`
  margin-top: 15px;
`
const IconContainer = styled.div`
  transform: ${(props) => (props.show ? "unset" : "rotate(180deg)")};
`
const HideSection = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`

const Headline = styled(Typography)`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Text = styled.div`
  padding-top: 15px;
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }
`
