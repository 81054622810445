import React, { useState, useEffect } from "react"
import styled from "styled-components"

// import material ui
import {
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Divider
} from "@material-ui/core"

// import app components
import Button from "components/button"

export default function DeckingCalculator(props) {
  const initialValues = {
    length: 0,
    height: 0,
    doors: 0,
    windows: 0,
    radioSelection: "0"
  }
  const initialTotal = {
    totalFeet: 0,
    totalSurface: 0
  }

  const [values, setValues] = useState(initialValues)
  const { length, height, doors, windows, radioSelection } = values

  const [total, setTotal] = useState(initialTotal)
  const { totalFeet, totalSurface } = total

  const handleChange = key => event => {
    setValues({ ...values, [key]: event.target.value })
  }

  const resetValues = () => {
    setValues(initialValues)
    setTotal(initialTotal)
  }

  const tableData = {
    header: [
      "",
      "Nominal Width(inches)",
      "Dressed Width (inches)",
      "Exposed Face Width (inches)",
      "Linear Feet Factor",
      "Surface Measure"
    ],
    rows: [
      [4, 3.5, 2.5, 4.8, 1.6],
      [6, 5.5, 4.5, 2.67, 1.33],
      [8, 7.5, 6.5, 1.85, 1.23],
      [10, 9.5, 8.5, 1.41, 1.18],
      [12, 11.5, 10.5, 1.14, 1.14]
    ]
  }

  const linearFeetFactor = tableData.rows[parseInt(radioSelection)][3]
  const surfaceMeasure = tableData.rows[parseInt(radioSelection)][4]
  const subtractedSurfaceArea = length * height - (doors + windows)

  useEffect(() => {
    // calculate total
    setTotal({
      totalFeet: Math.round(subtractedSurfaceArea * linearFeetFactor * 1.1 * 100) / 100,
      totalSurface: Math.round(subtractedSurfaceArea * surfaceMeasure * 1.1 * 100) / 100
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography id="wallAreaLabel">
              1. Enter the calculations for the total wall area (length x height):
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Total Length"
              value={values.length}
              onChange={handleChange("length")}
              aria-labelledby="wallAreaLabel"
              variant="filled"
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Total Height"
              value={values.height}
              onChange={handleChange("height")}
              aria-labelledby="wallAreaLabel"
              variant="filled"
              fullWidth
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography id="subtractionLabel">2. Subtract square footage of openings for windows and doors:</Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Doors"
              value={values.doors}
              onChange={handleChange("doors")}
              aria-labelledby="subtractionLabel"
              variant="filled"
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Windows"
              value={values.windows}
              onChange={handleChange("windows")}
              aria-labelledby="subtractionLabel"
              variant="filled"
              fullWidth
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom id="radioSelection">
          3. Select the appropriate factor for either linear or surface measurement:
        </Typography>
        <TableWrapper component="fieldset">
          <RadioGroup
            aria-labelledby="radioSelection"
            value={values.radioSelection}
            onChange={handleChange("radioSelection")}
          >
            <StyledTable>
              <tbody>
                <tr>
                  {!!tableData &&
                    tableData.header.map((header, index) => (
                      <th key={index}>
                        <Typography>{header}</Typography>
                      </th>
                    ))}
                </tr>
                {!!tableData.rows &&
                  tableData.rows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <FormControlLabel value={index.toString()} control={<Radio />} />
                      </td>
                      {!!row &&
                        row.map((val, index) => (
                          <td key={index}>
                            <Typography>{val}</Typography>
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </RadioGroup>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4">Result</Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledTextField label="Linear Feet" value={totalFeet} onChange={() => {}} variant="filled" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Surface Measure"
              value={totalSurface}
              onChange={() => {}}
              variant="filled"
              fullWidth
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="caption">10% is added to accomodate trim</Typography>
      </Grid>

      <Grid item xs={12}>
        <Controls>
          <StyledButton onClick={() => resetValues()}>Reset</StyledButton>
        </Controls>
      </Grid>
    </Grid>
  )
}

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`
const Controls = styled.div`
  display: flex;
`
const StyledButton = styled(Button)`
  margin-left: 0;
  margin-right: 20px;
`

const TableWrapper = styled(FormControl)`
  overflow: auto;
  max-width: 100%;
`

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 2px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.6rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.6rem;
  font-size: 1rem;
  line-height: 1.6rem;
  border-collapse: collapse;
  width: 100%;

  td {
    display: table-cell;
    vertical-align: inherit;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  td,
  th {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    font-feature-settings: "tnum";
    padding-left: 1.06667rem;
    padding-right: 1.06667rem;
    padding-top: 0.8rem;
    padding-bottom: calc(0.8rem - 1px);
  }
  th:first-child,
  td:first-child {
    padding-left: 0.8rem;
  }
`
