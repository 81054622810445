import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import InfiniteScroll from "react-infinite-scroller"
import { CircularProgress, Grid, Typography } from "@material-ui/core"
import { Magnify } from "mdi-material-ui"
import { isIE } from "react-device-detect"
import { BackgroundImage } from "react-image-and-background-image-fade"

// import app component
import Spacer from "components/spacer"
import Button from "components/button"
import GalleryDialog from "./galleryComponents/GalleryDialog"
import { useStore } from "store"
import { imageServices } from "services"

const maxResults = 16

const Gallery = (props) => {
  const { headline, link, categories } = props

  const [
    {
      appState: {
        gallery: { imagesByCategory }
      }
    },
    dispatch
  ] = useStore()

  const [isLoading, setIsLoading] = useState(false)

  const [activeCategories] = useState([categories])
  const [activeImage, setActiveImage] = useState(null)

  const categoryObject = imagesByCategory.find((o) => JSON.stringify(o.category) === JSON.stringify(activeCategories))
  const visibleImages = categoryObject ? categoryObject.images : null
  const hasMoreImages = categoryObject ? categoryObject.hasMoreImages : null
  const cursor = categoryObject ? categoryObject.cursor : ""

  useEffect(() => {
    loadImages(activeCategories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadImages = async (categories = []) => {
    setIsLoading(true)

    let result
    if (categories && categories.length > 0) {
      result = await imageServices.loadSearchResults(categories, cursor, maxResults)
    } else {
      result = await imageServices.loadImages(cursor, maxResults)
    }

    if (result) {
      dispatch({
        type: "SET_IMAGES",
        payload: {
          category: categories,
          ...result
        }
      })
    }

    setIsLoading(false)
  }

  const onScroll = () => {
    if (!isLoading && hasMoreImages) {
      loadImages(activeCategories)
    }
  }

  const renderGrid = () => {
    let grid = []

    const gridCount = Math.ceil(visibleImages.length / maxResults)

    for (let i = 0; i < gridCount; i++) {
      let images = visibleImages.slice(i * maxResults, i * maxResults + maxResults)

      const gridImages = images.map((o, j) => {
        return (
          <ImageContainer
            isIE={isIE}
            key={o.public_id}
            className={`element_${j + 1}`}
            onClick={() => setActiveImage(o)}
          >
            <BackgroundImage height="100%" width="100%" src={o.secure_url} />
            <Magnify />
          </ImageContainer>
        )
      })

      grid.push(
        <ImageGrid isIE={isIE} key={i}>
          {gridImages}
        </ImageGrid>
      )
    }

    return grid
  }

  return (
    <Container>
      {(link || headline) && (
        <Spacer pt={40} pb={30}>
          <Grid container justify="space-between" alignItems="center">
            {headline && (
              <Spacer pt={10} pb={10}>
                <Typography variant="h2" children={headline} />
              </Spacer>
            )}
            {link && (
              <Spacer pt={10} pb={10}>
                <Button to={link.url} children={link.title} />
              </Spacer>
            )}
          </Grid>
        </Spacer>
      )}
      <StyledInfiniteScroll loadMore={onScroll} hasMore={hasMoreImages} initialLoad={false} loader={null}>
        {visibleImages ? renderGrid() : <div key="placeholder" />}
      </StyledInfiniteScroll>

      {isLoading && (
        <Loader key="loader" container justify="center">
          <CircularProgress />
        </Loader>
      )}

      {activeImage && <GalleryDialog image={activeImage} onClose={() => setActiveImage(null)} />}
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`

const StyledInfiniteScroll = styled(InfiniteScroll)``

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0;

  ${({ isIE }) =>
    !isIE &&
    css`
      @media (min-width: 960px) {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 12vw 12vw 12vw 12vw 12vw 12vw 12vw 12vw;
        grid-template-areas: "element_1 element_1 element_1 element_2 element_4 element_4" "element_1 element_1 element_1 element_3 element_4 element_4" "element_5 element_5 element_6 element_6 element_7 element_7" "element_5 element_5 element_6 element_6 element_7 element_7" "element_8 element_8 element_8 element_9 element_9 element_9" "element_8 element_8 element_8 element_9 element_9 element_9" "element_10 element_12 element_12 element_13 element_14 element_15" "element_11 element_12 element_12 element_16 element_16 element_15";
      }

      .element_1 {
        grid-area: element_1;
      }

      .element_2 {
        grid-area: element_2;
      }

      .element_3 {
        grid-area: element_3;
      }

      .element_4 {
        grid-area: element_4;
      }

      .element_5 {
        grid-area: element_5;
      }

      .element_6 {
        grid-area: element_6;
      }

      .element_7 {
        grid-area: element_7;
      }

      .element_8 {
        grid-area: element_8;
      }

      .element_9 {
        grid-area: element_9;
      }

      .element_10 {
        grid-area: element_10;
      }

      .element_11 {
        grid-area: element_11;
      }

      .element_12 {
        grid-area: element_12;
      }

      .element_13 {
        grid-area: element_13;
      }

      .element_14 {
        grid-area: element_14;
      }

      .element_15 {
        grid-area: element_15;
      }

      .element_16 {
        grid-area: element_16;
      }
    `}
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  transition: ease all 0.2s;
  cursor: pointer;

  @media (min-width: 640px) {
    width: calc(50% - 5px);
  }

  div {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  ${({ isIE }) =>
    !isIE
      ? css`
          @media (min-width: 960px) {
            width: 100%;
            height: 100%;
          }
        `
      : css`
          @media (min-width: 960px) {
            width: calc((100% / 3) - 5px);
          }
        `}

  &:after {
    content: "";
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    transition: ease all 0.3s;
    opacity: 0;
  }

  svg {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: ease all 0.3s;
    opacity: 0;
    width: 32px;
    height: 32px;

    path {
      fill: #fff;
    }
  }

  &:hover {
    &:after,
    svg {
      opacity: 1;
    }
  }
`

const Loader = styled(Grid)`
  padding: 40px 0;
`

export default Gallery
