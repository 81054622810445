import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { ArrowUp } from "react-ionicons"
import { GatsbyImage } from "gatsby-plugin-image";

// import material ui
import { Typography } from "@material-ui/core"

// import app components
import Textarea from "../textarea"
import * as theme from "theme"

const Anchors = (props) => {
  const { tabsImage, tabs, firstItem } = props

  const { tabsPlaceholder } = useAnchorImageQuery()

  return (
    <Container firstItem={firstItem}>
      {tabs && tabs.length > 0 && (
        <>
          <Header firstItem={firstItem}>
            <HeaderLinks firstItem={firstItem}>
              <Headline variant="h4" children="In this section" />
              <Subline variant="h3" children="Table of Contents" />

              <Typography component="div">
                <List>
                  {tabs.map((o, index) => {
                    const anchorID = encodeURI(o.title.replace(/\s+/g, "-"))

                    return (
                      <ListItem key={index}>
                        <AnchorLink>
                          <a href={`#${anchorID}`}>
                            {o.title}
                            <StyledArrowRoundUp size="20px" />
                          </a>
                        </AnchorLink>
                      </ListItem>
                    )
                  })}
                </List>
              </Typography>
            </HeaderLinks>

            <HeaderImage>
              {(tabsImage?.localFile?.childImageSharp?.gatsbyImageData || tabsPlaceholder?.localFile?.childImageSharp?.gatsbyImageData) && (
                <GatsbyImage
                  image={tabsImage?.localFile?.childImageSharp?.gatsbyImageData || tabsPlaceholder?.localFile?.childImageSharp?.gatsbyImageData} />
              )}
            </HeaderImage>
          </Header>

          {tabs.map((o, index) => {
            const anchorID = encodeURI(o.title.replace(/\s+/g, "-"))

            return (
              <Section key={index} id={anchorID}>
                <SectionHeadline children={o.title} variant="h3" />
                <Textarea content={o.text} />
              </Section>
            )
          })}
        </>
      )}
    </Container>
  );
}

const AnchorLink = styled.div`
  a {
    display: block;
    text-decoration: none;
    color: ${theme.colors.primary};

    &:hover {
      span {
        transform: rotate(180deg);
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.firstItem ? 0 : "40px")};
  margin-bottom: 30px;
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
`

const HeaderLinks = styled.div`
  width: 100%;
  padding-bottom: 20px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    padding-top: ${(props) => (props.firstItem ? 0 : "20px")};
    padding-right: 30px;
    width: 450px;
  }
`

const List = styled.ul`
  margin-left: 1.6rem;
`

const ListItem = styled.li`
  position: relative;
  list-style: upper-roman;
  background-color: transparent;
  margin-bottom: 15px;
  padding-right: 20px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -25px;
    width: calc(100% + 25px);
    height: 1px;
    background: ${theme.colors.lightmediumgrey};
  }
`

const StyledArrowRoundUp = styled(ArrowUp)`
  position: absolute;
  right: 0;
  bottom: 2px;
  display: inline-flex;
  transform: rotate(135deg);
  transition: ease all 0.2s;
`

const Headline = styled(Typography)`
  color: ${theme.colors.textgrey};
  margin-bottom: 10px;
`

const Subline = styled(Typography)`
  text-transform: uppercase;
  color: ${theme.colors.primary};
  margin-bottom: 30px;
`

const HeaderImage = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: calc(100% - 450px);
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 600px;
  }

  img {
    max-height: 100%;
  }
`

const SectionHeadline = styled(Typography)`
  color: ${theme.colors.primary};
  position: relative;
  margin-bottom: 30px;

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${theme.colors.lightmediumgrey};
  }
`

const Section = styled.section`
  clear: both;
  margin-bottom: 30px;
  display: block;
  h1,
  h2,
  h3,
  h4,
  h5 {
    /* cleared left to prevent images above floating left of heading */
    clear: left;
  }
`
const useAnchorImageQuery = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`{
  wp {
    themeSettings {
      siteOptions {
        tabsPlaceholder {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`
  )
  return siteOptions
}

export default Anchors
