import React from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"

// import app components
import Form from "components/form"
import Button from "components/button"
import Spacer from "components/spacer"
import SidebarContent from "components/sidebarContent"
import * as theme from "theme"
import { getUrlPath } from "utils/getUrlPath"
import { prepCartForListField } from "."

const Checkout = ({ formId, cartFieldId, uri, cart, setIsConfirmingOrder, ...rest }) => {
  const sidebar = (
    <Spacer p={20}>
      <StyledButton fullWidth onClick={() => setIsConfirmingOrder(false)}>
        Go Back
      </StyledButton>
    </Spacer>
  )

  return (
    <SidebarContent
      hideHeader
      image={null}
      title={"Checkout"}
      slug={getUrlPath(uri)}
      sidebar={sidebar}
      edgesSize={"none"}
      position={"right"}
    >
      <div {...rest}>
        <Spacer pb={30}>
          <Typography component="h5" variant="h3" gutterBottom>
            Order Summary
          </Typography>
          <Summary>
            {!!cart &&
              cart.map((item, index) => (
                <li key={index}>
                  <Typography>
                    {item.amount}x {item.name}
                  </Typography>
                </li>
              ))}
          </Summary>
        </Spacer>
        <Typography component="h5" variant="h3" gutterBottom>
          Mailing Information
        </Typography>
        <Form hideTitle formId={formId} hiddenFields={[{ content: prepCartForListField(cart), id: cartFieldId }]} />
      </div>
    </SidebarContent>
  )
}

const Summary = styled.ul`
  margin-bottom: 20px;
`
const StyledButton = styled(Button)`
  border: 1px solid ${theme.colors.primary};
`

export default Checkout
