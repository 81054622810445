import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography } from "@material-ui/core"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

// import app components
import Textarea from "components/textarea"
import Button from "components/button"
import Link from "components/link"

import * as theme from "theme"

export default function Publication(props) {
  const { publication } = props

  const handleClick = (element) => {
    if (process.env.GATSBY_GOOGLE_ANALYTICS) {
      trackCustomEvent({
        category: "Download",
        action: "Click",
        label: element.publicationTitle,
        value: 1
      })
    }
  }

  return <>
    {publication.map((item, index) => {
      return (
        <Item key={index}>
          <ImageContainer>
            {item?.publicationImage?.localFile?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage image={item.publicationImage.localFile.childImageSharp.gatsbyImageData} />
            )}
          </ImageContainer>

          <Content>
            <Typography variant="h4" children={item.publicationTitle} gutterBottom />
            <Textarea content={item.publicationDescription} />

            {item.orderLink && (
              <Link to={item.orderLink} style={{ display: "block", marginBottom: "20px" }}>
                <Button>Order a hard copy</Button>
              </Link>
            )}

            {item.downloadLinks && (
              <Downloads>
                {item.downloadLinks
                  .filter((download) => !!download?.links?.localFile?.publicURL || !!download?.links?.sourceUrl)
                  .map((download, index) => {
                    return (
                      <Typography
                        className={`download pdf-download`}
                        key={index}
                        component="div"
                        onClick={() => handleClick(item)}
                      >
                        <DownloadLink
                          href={download?.links?.localFile?.publicURL || download?.links?.sourceUrl}
                          target="_blank"
                        >
                          <Button children={download?.language} />
                        </DownloadLink>
                      </Typography>
                    )
                  })}
              </Downloads>
            )}
          </Content>
        </Item>
      );
    })}
  </>;
}

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
  border-bottom: 1px solid ${theme.colors.lightgrey};

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
`

const ImageContainer = styled.div`
  width: 150px;
  margin-right: 30px;
  margin-bottom: 20px;
`

const Content = styled.div`
  width: 100%;
  text-align: center;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: auto;
    flex: 1;
    text-align: left;
  }
`

const Downloads = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
  max-width: 600px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
`

const DownloadLink = styled.a`
  display: block;
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 10px;
`
