import React from "react"
import styled from "styled-components"

// import app components
import Wysiwyg from "../textarea"

export default function Textarea(props) {
  const { text } = props

  return (
    <Container>
      <Wysiwyg content={text} />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 20px;
`
