import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const WideImage = ({ image }) => {
  return (
    <WideImageContainer>
      {image && (
        <GatsbyImage
          height={`fit-content`}
          width={`fit-content`}
          objectFit={`cover`}
          image={
            image?.localFile?.childImageSharp?.gatsbyImageData || image?.localFile?.childImageSharp?.gatsbyImageData
          }
          alt={image?.altText}
        />
      )}
    </WideImageContainer>
  )
}

const WideImageContainer = styled.div`
  object-fit: cover;
  width: 100%;
  text-align: center;
  .gatsby-image-wrapper {
    height: 573px;
  }
`

export default WideImage
