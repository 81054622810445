import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Form from "components/form"
import Spacer from "components/spacer"

const NewsletterModule = (props) => {
  const { customFormId, headline, newsletterType, sidebarForm, subheadline } = props

  const {
    newsletterBlogHeadline,
    newsletterBlogSubheading,
    newsletterProjectHeadline,
    newsletterProjectSubheading,
    newsletterHomeHeadline,
    newsletterHomeSubheading
  } = useNewsletterOptionsQuery()

  const headlineText = !!headline
    ? headline
    : newsletterType === "blog"
    ? newsletterBlogHeadline
    : newsletterType === "project"
    ? newsletterProjectHeadline
    : newsletterType === "home"
    ? newsletterHomeHeadline
    : ""

  const subheadText = !!subheadline
    ? subheadline
    : newsletterType === "blog"
    ? newsletterBlogSubheading
    : newsletterType === "project"
    ? newsletterProjectSubheading
    : newsletterType === "home"
    ? newsletterHomeSubheading
    : ""

  return (
    <NewsletterCont>
      <Spacer p={sidebarForm ? 15 : 30}>
        {!!headlineText && <TypographyFullWidth variant="h2" children={headlineText} gutterBottom />}
        {!!subheadText && (
          <TypographySubhead children={subheadText} gutterBottom sidebarForm={sidebarForm} variant="body1" />
        )}
        <Form hideTitle formId={customFormId ? customFormId : 40} sidebarForm={sidebarForm} style={{ padding: 0 }} />
      </Spacer>
    </NewsletterCont>
  )
}

const NewsletterCont = styled.div`
  background: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  margin-top: -1px;
  margin-bottom: -1px;

  .form-group.form-field-full-width {
    width: calc(100% - 10px);
  }
`

const TypographySubhead = styled(({ sidebarForm, ...rest }) => <Typography {...rest} />)`
  color: #4c656c;
  display: block;
  font-family: HalisGr, sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  margin: ${(props) => (props.sidebarForm ? "0 auto" : "0 auto 1.2rem")};
  text-align: center;
  width: calc(100%);
`

const TypographyFullWidth = styled(Typography)`
  text-align: center;
  width: 100%;
`

const useNewsletterOptionsQuery = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      query {
        wp {
          themeSettings {
            siteOptions {
              newsletterBlogHeadline
              newsletterBlogSubheading
              newsletterProjectHeadline
              newsletterProjectSubheading
              newsletterHomeHeadline
              newsletterHomeSubheading
            }
          }
        }
      }
    `
  )
  return siteOptions
}

export default NewsletterModule
