import produce from "immer"
import { set } from "lodash"

const handleMaximumItems = (cart, setCart, sections) => {
  cart.forEach((item, index) => {
    const updatedCart = [...cart]

    // * remove item if amount is zero
    if (item.amount === 0 || item.amount === "0") {
      // if item amount is 0 remove it from the cart
      const cartWithoutItem = updatedCart.filter(({ name }) => name !== item.name)
      setCart(cartWithoutItem)
    }

    // * handle max per item
    if (item.maxPerItem && item.amount > item.maxPerItem) {
      const newCart = produce(cart, (draft) => {
        set(draft, `${index}.amount`, item.maxPerItem)
      })

      // if maxPerItem is set and item.amount is larger than the max, set it to the max instead
      setCart(newCart)
    }
  })
}
export default handleMaximumItems
