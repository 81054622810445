import React from "react"
import styled, { css } from "styled-components"
import { Typography, Button as MuiButton } from "@material-ui/core"

import BackgroundImage from "components/backgroundImage"
import Edges from "components/edges"
import Link from "components/link"
import Textarea from "./Textarea"

import PlayIcon from "../../assets/svg/playIcon.svg"
import BuildIcon from "../../assets/svg/buildIcon.svg"
import ArrowIcon from "../../assets/svg/arrowIcon.svg"

const CardButton = (props) => {
  const { to, ...rest } = props
  return (
    <Link to={to}>
      <Button {...rest} />
    </Link>
  )
}

const Button = styled(MuiButton)`
  padding: 6px 24px;
  height: 50px;
  border-radius: 40px;
  border: 1px solid #dedede;
  text-transform: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  svg {
    width: 36px;
    height: 36px;
    margin-left: 14px;
  }
`

const Cards = (props) => {
  const { items } = props

  const getBorderColor = (index) => {
    switch (index) {
      case 0:
        return `#86a15b`
      case 1:
        return `#5693BB`
      case 2:
        return `#CE663F`
      case 3:
        return `#FFD687`
      default:
        return `#86a15b`
    }
  }

  const renderButtonIcon = (buttonIcon) => {
    switch (buttonIcon) {
      case "play":
        return <PlayIcon />
      case "build":
        return <BuildIcon />
      default:
        return <ArrowIcon />
    }
  }

  return (
    <Container>
      <Edges size="lg">
        <Inner>
          {items &&
            items.map(({ headline, text, textWysiwyg, button, image, buttonIcon }, index) => {
              return (
                <Card key={index} index={index} borderColor={() => getBorderColor(index)}>
                  <Content>
                    {headline && (
                      <Typography variant="h3" gutterBottom>
                        {headline}
                      </Typography>
                    )}

                    {textWysiwyg ? (
                      <Textarea text={textWysiwyg} />
                    ) : (
                      text && <Typography gutterBottom>{text}</Typography>
                    )}

                    {button && (
                      <CardButton to={button?.url} aria-label={headline}>
                        {button?.title}
                        {renderButtonIcon(buttonIcon)}
                      </CardButton>
                    )}
                  </Content>
                  <ImageContainer>{image && <BackgroundImage position="absolute" image={image} />}</ImageContainer>
                </Card>
              )
            })}
        </Inner>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
`
const Inner = styled.div`
  position: relative;
  z-index: 1;
`
const Card = styled.div`
  position: relative;
  box-shadow: 19px 20px 20px rgba(201, 218, 218, 0.25);
  margin-bottom: 30px;
  @media (min-width: 800px) {
    margin-bottom: 50px;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  min-height: 340px;
  @media (min-width: 1120px) {
    ${({ index }) =>
      index % 2 === 1
        ? css`
            margin-left: 100px;
          `
        : css`
            margin-right: 100px;
          `}
  }
  > div {
    width: 100%;
  }
  @media (min-width: 800px) {
    border-left: 14px solid ${({ borderColor }) => borderColor};
    > div {
      width: 50%;
    }
  }
  @media (max-width: 799px) {
    border-top: 14px solid ${({ borderColor }) => borderColor};
  }
`
const Content = styled.div`
  padding: 25px;
  @media (min-width: 800px) {
    padding: 50px 60px;
  }
`
const ImageContainer = styled.div`
  position: relative;
  min-height: 230px;
`

export default Cards
