import React from "react"
import styled from "styled-components"

// import material ui
import { Typography } from "@material-ui/core"
import { Magnify } from "mdi-material-ui"

// import app components
import Link from "components/link"
import BackgroundImage from "components/backgroundImage"
import Button from "components/button"

import { getUrlPath } from "utils/getUrlPath"
import * as theme from "theme"
import { useStore } from "store"

const Boxes = (props) => {
  const { headline, boxes, hideSidebar, buttonVisibility } = props
  const dispatch = useStore()[1]

  const showLightBox = (link, image) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: 0,
        slides: [{ link, image }],
        options: {
          thumbs: false
        }
      }
    })
  }

  const LinkWrapper = (url, el, props) => {
    if (!url) {
      return el
    }

    if (url.includes("youtu")) {
      // youtube
      // eslint-disable-next-line
      return <div onClick={() => showLightBox({ url }, props.image)} children={el} style={{ cursor: "pointer" }} />
    } else if (getUrlPath(url).includes("http")) {
      // external link
      return <a href={url} children={el} aria-label="External link" />
    } else {
      // internal link
      return <Link to={getUrlPath(url)} children={el} />
    }
  }

  return (
    <Container>
      {!!headline && <Typography variant="h2" children={headline} gutterBottom />}
      {!!boxes && (
        <Grid>
          {boxes.map(({ image, link, text, headline }, index) => {
            let imageObject = image

            if (!image && link && link?.url.includes("youtu")) {
              const youtube_id = link.url.split("/").pop()
              const youtube_img = `https://img.youtube.com/vi/${youtube_id}/0.jpg`

              // Generate Gatsby image markup
              imageObject = {
                localFile: {
                  childImageSharp: {
                    fluid: {
                      base64: "",
                      aspectRatio: 480 / 360,
                      src: youtube_img,
                      srcSet: `${youtube_img} 75w`,
                      sizes: "(max-width: 300px) 100vw, 300px"
                    }
                  }
                }
              }
            }

            return (
              <Box key={index} hideSidebar={hideSidebar} hideButton={buttonVisibility}>
                {link &&
                  LinkWrapper(
                    link.url,
                    <ImageContainer>
                      <BackgroundImage image={imageObject} />
                      <Magnify />
                      <SrOnly>{headline + " page"}</SrOnly>
                    </ImageContainer>,
                    { image: imageObject }
                  )}

                {headline &&
                  LinkWrapper(
                    link.url,
                    <Headline color="primary" variant="h3" component="h3">
                      {headline}
                    </Headline>,
                    { image: imageObject }
                  )}

                {text && <Typography gutterBottom>{text}</Typography>}

                {!buttonVisibility &&
                  link &&
                  LinkWrapper(
                    link.url,
                    <ButtonContainer>
                      <Button children="More info" />
                    </ButtonContainer>,
                    { image: imageObject }
                  )}
              </Box>
            )
          })}
          {/* placeholders for grid layout */}
          <Box hideSidebar={hideSidebar} style={{ height: 0, marginBottom: 0, padding: 0 }} />
          <Box hideSidebar={hideSidebar} style={{ height: 0, marginBottom: 0, padding: 0 }} />
        </Grid>
      )}
    </Container>
  )
}

export default Boxes

const Container = styled.div`
  margin: 30px 0;
`

const Box = styled.div`
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  padding-bottom: ${(props) => (!props.hideButton ? "44px" : 0)};

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 10px);
    margin-bottom: 40px;
  }

  ${(props) =>
    props.hideSidebar &&
    `
    @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
      width: calc(100% / 3 - 15px);
    }
  
  `}

  &:last-of-type {
    margin-right: 0;
  }
  a {
    text-decoration: none;
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
  position: relative;
  background-color: #c8c8c8;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;
  transition: ease all 0.3s;

  &:after {
    content: "";
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    transition: ease all 0.3s;
    opacity: 0;
  }

  svg {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: ease all 0.3s;
    opacity: 0;
    width: 32px;
    height: 32px;

    path {
      fill: #fff;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 25px 0px;

    &:after,
    svg {
      opacity: 1;
    }
  }
`

const SrOnly = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`

const Headline = styled(Typography)`
  margin-bottom: 15px;
`
