import React from "react"
import styled, { css } from "styled-components"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import PlayIcon from "../../../static/images/icons/play.svg"
import BackgroundImage from "../backgroundImage"

import * as theme from "theme"
import { useStore } from "../../store"

export default function Videos(props) {
  const { headline, videos } = props

  const dispatch = useStore()[1]

  const showLightBox = (index) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: videos,
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container>
      <Typography variant="h2">{headline}</Typography>
      <Grid>
        {!!videos &&
          videos.map(({ title, image, link }, index) => {
            let imageObject = image

            const isYoutube = !image && link && link?.url.includes("youtu")

            if (isYoutube) {
              const youtube_id = link.url.split("/").pop()
              const youtube_img = `https://img.youtube.com/vi/${youtube_id}/0.jpg`

              // Generate Gatsby image markup
              imageObject = {
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      height: 360,
                      width: 480,
                      layout: "constrained",
                      backgroundColor: "#eee",
                      images: {
                        fallback: {
                          sizes: "(min-width: 300px) 300px, 100vw",
                          src: youtube_img,
                          srcSet: `${youtube_img} 75w`
                        },
                        sources: [
                          {
                            sizes: "(min-width: 300px) 300px, 100vw",
                            srcSet: `${youtube_img} 75w`,
                            type: "image/jpeg"
                          }
                        ]
                      }
                    }
                  }
                }
              }
            }

            return (
              <Video key={index} onClick={() => showLightBox(index)}>
                {isYoutube && (
                  <IconContainer className="play-icon">
                    <PlayIcon />
                  </IconContainer>
                )}

                {imageObject && <BackgroundImage image={imageObject} maxWidth={400} />}
                <Overlay>
                  <Typography color="inherit" component="h3" variant="h4">
                    {title}
                  </Typography>
                </Overlay>
              </Video>
            )
          })}
        {/* placeholders for grid layout */}
        <Video style={{ height: 0, marginTop: 0 }} />
        <Video style={{ height: 0, marginTop: 0 }} />
      </Grid>
    </Container>
  )
}

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  padding: 30px 0;
`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Video = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 10px);
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: calc(100% / 3 - 15px);
  }

  ${center}
  cursor: pointer;
  position: relative;
  height: 175px;
  background: lightgrey;
  margin-top: 14px;

  &:hover {
    .play-icon {
      opacity: 0;
    }
  }
`
const IconContainer = styled.div`
  position: relative;
  z-index: 1;
  pointer-events: none;
  padding-left: 14px;
  padding-top: 10px;
  height: 55px;
  width: 55px;
  background-color: rgba(44, 49, 44, 0.6);
  border-radius: 50%;
  transition: all 0.2s;
  svg {
    width: 35px;
    height: 35px;
    opacity: 0.9;
  }
`
const Overlay = styled.div`
  position: absolute;
  ${center}
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  background: rgba(44, 49, 44, 0.8);
  opacity: 0;
  text-align: center;
  padding: 0 10px;
  &:hover {
    opacity: 1;
  }
  color: white;
`
