import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

// import app components

export default function LogoTitle(props) {
  const { headline, logo } = props

  return (
    <div className="flex flex-row items-end mb-[24px]">
      {logo?.localFile && <GatsbyImage image={logo?.localFile?.childImageSharp?.gatsbyImageData} className="mr-2" />}
      {headline && <h2>{headline}</h2>}
    </div>
  )
}
