import React from "react"

// import app components
import Tabs from "./Anchors"
import Accordion from "./Accordion"
import Ad from "./Ad"
import Boxes from "./Boxes"
import FaqAccordion from "./FaqAccordion"
import Calculator from "./Calculator"
import Downloads from "./Downloads"
import Form from "./Form"
import Members from "./Members"
import NewsletterModule from "./NewsletterModule"
import NewsletterTeaser from "./NewsletterTeaser"
import Publication from "./Publication"
import TextareaCta from "./TextareaCta"
import TextareaVideo from "./TextareaVideo"
import Textarea from "./Textarea"
import Gallery from "./Gallery"
import Videos from "./Videos"
import NewsletterSignup from "./NewsletterSignup"
import Shop from "./Shop"
import Architects from "./Architects"
import Callout from "./Callout"
import Cards from "./Cards"
import TextImage from "./TextImage"
import LogoTitle from "./LogoTitle"

const FlexibleContent = (props) => {
  const { rows, data } = props

  if (process.env.GATSBY_ACTIVE_LANGUAGE === "EN") {
    //  Add dummy flexible content element for ad
    if (!data.hideMemberAds && rows.findIndex((o) => o.__typename === "WpPage_Acfmodules_Modules_Ad") === -1) {
      rows.splice(1, 0, { __typename: "WpPage_Acfmodules_Modules_Ad" })
    }
    //  Add dummy flexible content element for newsletter
    if (
      !data.hideNewsletterTeaser &&
      rows.findIndex((o) => o.__typename === "WpPage_Acfmodules_Modules_NewsletterTeaser") === -1
    ) {
      rows.splice(3, 0, { __typename: "WpPage_Acfmodules_Modules_NewsletterTeaser" })
    }
  }

  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        if (!__typename) {
          return null
        }

        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Accordion,
          Ad,
          Boxes,
          Calculator,
          Downloads,
          Form,
          Members,
          NewsletterTeaser,
          NewsletterSignup,
          NewsletterModule,
          LogoTitle,
          Publication,
          Textarea,
          Tabs,
          TextareaCta,
          TextareaVideo,
          Videos,
          Gallery,
          Shop,
          Architects,
          FaqAccordion,
          Callout,
          Cards,
          TextImage
        }
        const Component = components[type]
        return Component && <Component key={index} firstItem={index === 0 ? true : false} {...rowData} {...data} />
      })
  }
}

export default FlexibleContent
