import React from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"

// import app components
import Button from "components/button"
import * as theme from "theme"

const Callout = ({ text, link }) => {
  return (
    <Container>
      <TextContainer variant="h3">{text}</TextContainer>
      <a href={link.url} target={link.target}>
        <Button children={link.title} />
      </a>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  border: 1px solid ${theme.colors.primary};

  @media (min-width: 850px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 16px !important;

    @media (min-width: 850px) {
      margin-bottom: 0;
      font-size: 20px !important;
    }
  }

  svg {
    position: relative;
    top: 5px;
  }
`
const TextContainer = styled(Typography)`
  display: flex;
  gap: 7px;
  justify-content: center;

  @media (min-width: 850px) {
    justify-content: flex-start;
  }
`

export default Callout
