import React from "react"
import styled, { css } from "styled-components"
import * as theme from "theme"

// import app components
import GravityForm from "../form"
const h3 = { ...theme.mui.typography.h4 }
const p = { ...theme.mui.typography.body2 }

const Form = (props) => (
  <Container>
    <GravityForm {...props} />
  </Container>
)

export default Form

const Container = styled.div`
  .dateHeader {
    label {
      ${h3}
      text-transform: uppercase;
    }
  }
  .htmlContent {
    h3 {
      ${h3}
      text-decoration:underline;

      padding-bottom: 5px;
    }
    p {
      ${p}
      padding-left:15px;
    }
  }
  .busTransport:before {
    content: "";
    white-space: pre;
  }
  .completeRequestText,
  .fullPayment {
    p {
      ${p}
    }
  }
  .field-description {
    ${p}
  }
  .cedraSummitQuestion {
    padding-left: 15px;
  }
`
