import { request } from "."

export const loadImages = async (cursor, maxResults) => {
  const endpoint = `wp/v2/cloudinary_images?max_results=${maxResults}${cursor ? "&next_cursor=" + cursor : ""}`
  const data = await request(endpoint)
  return data
}

export const loadCategoryImages = async (category, cursor, maxResults) => {
  const endpoint = `wp/v2/cloudinary_category_images?max_results=${maxResults}&category=${category}${
    cursor ? "&next_cursor=" + cursor : ""
  }`

  const data = await request(endpoint)
  return data
}

export const loadSearchResults = async (expression, cursor, maxResults) => {
  const endpoint = `wp/v2/cloudinary_search?search=${expression}&max_results=${maxResults}${
    cursor ? "&next_cursor=" + cursor : ""
  }`

  const data = await request(endpoint)
  return data
}

export const loadImageDetails = async public_id => {
  const endpoint = `wp/v2/cloudinary_image_details?public_id=${public_id}`

  const data = await request(endpoint)
  return data
}
