import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import slugify from "slugify"

import { getUrlPath } from "utils/getUrlPath"

const Architects = (props) => {
  const { headline } = props
  const { allWpArchitect } = useArchitectQuery()

  return (
    <div id={`architect-${headline && slugify(headline, { lower: true })}`}>
      {headline && <Typography variant="h3" children={headline} gutterBottom />}

      <ArchitectsContainer size="sm">
        {allWpArchitect?.nodes.map((o) => {
          return (
            <ArchitectContainer key={o.id} to={getUrlPath(o.uri)}>
              <ImageContainer>
                {o.acf?.image?.sourceUrl && <img src={o.acf?.image?.sourceUrl} alt="Logo" />}
              </ImageContainer>

              <Title variant="h6" children={Parser(o.title)} />
            </ArchitectContainer>
          )
        })}
        <ArchitectContainer to={"/"} style={{ opacity: 0, pointerEvents: "none" }} />
        <ArchitectContainer to={"/"} style={{ opacity: 0, pointerEvents: "none" }} />
      </ArchitectsContainer>
    </div>
  )
}

const ArchitectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
`

const ArchitectContainer = styled(Link)`
  width: 100%;
  margin-bottom: 30px;
  text-decoration: none;

  @media (min-width: 640px) {
    width: calc(50% - 10px);
    margin-bottom: 10px;
  }

  @media (min-width: 960px) {
    width: calc(33.3333% - 10px);
  }
`

const Title = styled(Typography)`
  text-align: center;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  max-height: 100%;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const useArchitectQuery = () => {
  const allWpArchitect = useStaticQuery(
    graphql`
      query {
        allWpArchitect(limit: 1000, sort: { order: ASC, fields: title }) {
          nodes {
            id
            title
            uri
            acf {
              image {
                sourceUrl
              }
            }
          }
        }
      }
    `
  )
  return allWpArchitect
}

export default Architects
