import produce from "immer"
import { set } from "lodash"

const addToCart = (item, max, maxPerItem, sectionIndex, cart, setCart) => {
  const existingItemIndex = cart.findIndex(({ name }) => name === item)
  const itemExists = existingItemIndex > -1

  if (itemExists) {
    const newCart = produce(cart, (draft) => {
      set(draft, `${existingItemIndex}.amount`, parseInt(draft[existingItemIndex].amount, 10) + 1)
    })

    setCart(newCart)
  } else {
    // item doesn't exist in cart so just add it to the cart
    setCart([...cart, { name: item, max_per_section: max, maxPerItem, sectionIndex, amount: 1 }])
  }
}

export default addToCart
