import React from "react"
import styled from "styled-components"

import DeckingCalculator from "./calculatorComponents/DeckingCalculator"
import SidingCalculator from "./calculatorComponents/SidingCalculator"

export default function Calculator({ calculator, ...rest }) {
  return (
    <Container {...rest}>
      {calculator === "decking" ? <DeckingCalculator /> : calculator === "siding" && <SidingCalculator />}
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 30px;
`
