import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import slugify from "slugify"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Link from "components/link"

import * as theme from "theme"
import { useStore } from "store"

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len)
  if (n > len) throw new RangeError("getRandom: more elements taken than available")
  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

export default function Ad() {
  const [
    {
      appState: { geoInformation }
    }
  ] = useStore()

  const [members, setMembers] = useState(null)

  const {
    allWpRetailer: { nodes: retailer }
  } = useRetailerQuery()

  useEffect(() => {
    if (geoInformation && geoInformation?.subdivisions?.[0]?.names?.en && retailer) {
      const filteredRetailer = retailer.filter(
        o => o?.states?.nodes?.[0]?.slug === slugify(geoInformation?.subdivisions?.[0]?.names?.en, { lower: true })
      )

      // extract unique members from retailers
      const allMembers = []
      filteredRetailer.map(({ Retailer: { premiumRetailer } }) => {
        if (premiumRetailer && allMembers.findIndex(o => o.id === premiumRetailer.id) === -1) {
          return allMembers.push(premiumRetailer)
        } else {
          return allMembers
        }
      })

      const members = allMembers.length > 3 ? getRandom(allMembers, 3) : allMembers

      setMembers(members)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoInformation])

  return (
    <>
      {members && members.length > 0 ? (
        <Container>
          <Headline children="Available from our Premium Retailers" variant="h2" gutterBottom />

          <Items id="premium-retailer-ad" to="/find-a-retailer/">
            {members.map(o => {
              return (
                <Item key={o.id}>
                  <ImageContainer>
                    {o?.Members?.smallMemberLogo?.sourceUrl && (
                      <img src={o.Members.smallMemberLogo.sourceUrl} alt="Logo" />
                    )}
                  </ImageContainer>
                  <Title children={o.post_title} />
                </Item>
              )
            })}
          </Items>
        </Container>
      ) : null}
    </>
  )
}

const Container = styled.div`
  margin: 40px 0;

  @media (min-width: 768px) {
    margin: 4vw 0;
  }
`

const Headline = styled(Typography)`
  text-transform: unset;
`

const Items = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: ${theme.mui.breakpoints.values.sm - 1}px) {
    > div:nth-child(1),
    > div:nth-child(2) {
      display: none;
    }
  }
`

const Item = styled.div`
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(33.3333% - 20px);
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-bottom: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const Title = styled(Typography)`
  text-align: center;
`

const useRetailerQuery = () => {
  const allWpRetailer = useStaticQuery(
    graphql`
      query {
        allWpRetailer {
          nodes {
            title
            states {
              nodes {
                slug
              }
            }
            Retailer {
              premiumRetailer {
                ... on WpMember {
                  id
                  title
                  Members {
                    smallMemberLogo {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpRetailer
}
