import React from "react"
import styled from "styled-components"

// import app components
import Wysiwyg from "../textarea"
import NewsletterModule from "./NewsletterModule"

import * as theme from "theme"

export default function TextareaCta(props) {
  const { text } = props

  return (
    <Container>
      <Content>
        <Wysiwyg content={text} />
      </Content>
      <Cta>
        <NewsletterModule
          customFormId={50}
          headline=""
          subheadline="Sign up now for our DIY Project Newsletter"
          sidebarForm={true}
        />
      </Cta>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    margin-bottom: 4vw;
  }

  @media screen and (max-width: ${theme.mui.breakpoints.values.md}px) {
    flex-wrap: wrap;
  }
`

const Content = styled.div``

const Cta = styled.div`
  flex-shrink: 0;
  margin-left: 20px;
  width: 300px;
`
