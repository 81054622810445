import React, { useState } from "react"
import styled, { css } from "styled-components"

import { useForm } from "react-hook-form"
import { TextField, Typography, CircularProgress } from "@material-ui/core"

import Button from "components/button"

const NewsletterSignup = (props) => {
  const { headline = "Newsletter Signup", ...rest } = props

  const [state, setState] = useState(`default`)
  const { register, handleSubmit } = useForm()

  const formId = 48
  const endpoint = `${process.env.GATSBY_WP}/wp-json/gf/v2/forms/${formId}/submissions`

  const [submitForm, { loading }] = useFetch(endpoint, {
    method: "POST",
    "Content-Type": "application/x-www-form-urlencoded",
    onCompleted: (data) => {
      if (data?.is_valid) {
        setState(`completed`)
      }
    },
    onError: (err) => {
      if (err?.validation_messages && err.validation_messages[`2`].startsWith(`This field requires a unique entry`)) {
        setState("error-already-signed-up")
      } else {
        setState("error-something-went-wrong")
      }
    }
  })

  const onSubmit = (values) => {
    const formData = new FormData()
    Object.keys(values).map((key) => formData.append(key, values[key])) // append values to formData

    submitForm({ body: formData })
  }

  return (
    <Container {...rest}>
      <Typography variant="h3" gutterBottom>
        {headline}
      </Typography>
      {state === `default` ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField fullWidth inputRef={register} id="input_1" name="input_1" label="Name" required />
          <TextField fullWidth inputRef={register} id="input_2" name="input_2" label="Email" required type="email" />

          <Button type="submit" disabled={loading}>
            <ButtonText loading={loading}>Submit</ButtonText>
            <LoaderContainer loading={loading}>
              <CircularProgress alt="progressbar" size={25} color="inherit" />
            </LoaderContainer>
          </Button>
        </Form>
      ) : state === `completed` ? (
        <div>
          <Typography variant="h4">Success</Typography>
          <Typography>You have subscribed to the newsletter.</Typography>
        </div>
      ) : state === `error-already-signed-up` ? (
        <div>
          <Typography variant="h4">Error</Typography>
          <Typography>You are already subscribed to the newsletter.</Typography>
        </div>
      ) : (
        state === `error-something-went-wrong` && (
          <div>
            <Typography variant="h4">Error</Typography>
            <Typography>
              Something went wrong. Please
              {/* eslint-disable  jsx-a11y/click-events-have-key-events */}
              {/* eslint-disable  jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => setState(`default`)}
                css={css`
                  cursor: pointer;
                  text-decoration: underline;
                `}
              >
                try again.
              </span>
            </Typography>
          </div>
        )
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 30px 0;
`

const Form = styled.form`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .MuiInputBase-root {
    margin-bottom: 20px;
  }
`

const LoaderContainer = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0;
  ${({ loading }) =>
    loading &&
    css`
      opacity: 1;
    `}
`
const ButtonText = styled.span`
  ${({ loading }) =>
    loading &&
    css`
      opacity: 0;
    `}
`

const useFetch = (endpoint, options = {}) => {
  const { onError = null, onCompleted = null, ...restOptions } = options

  const [state, setState] = useState({
    error: null,
    data: null,
    loading: false,
    called: false
  })

  const callback = async (args) => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null
    })

    await fetch(endpoint, {
      method: "get",
      ...restOptions,
      ...args
    })
      .then(async (response) => {
        const json = await response.json()

        setState({
          ...state,
          loading: false,
          data: response.status === 200 ? json : null,
          called: true,
          error: response.status !== 200 ? json : null
        })

        if (response.status === 200) {
          onCompleted && typeof onCompleted === `function` && onCompleted(json)
        } else {
          onError && typeof onError === `function` && onError(json)
        }
      })
      .catch((error) => {
        setState({ ...state, error, loading: false, called: true })
        onError && typeof onError === `function` && onError(error)
      })
  }

  return [callback, state]
}

export default NewsletterSignup
