// returns the amount of items in the cart
// or 0 if item doesn't exist

const itemsInCart = (item, cart) => {
  const existingItemIndex = cart.findIndex(({ name }) => name === item)
  const itemExists = existingItemIndex > -1
  if (itemExists) {
    const existingItem = cart[existingItemIndex]
    return existingItem.amount
  } else {
    return 0
  }
}

export default itemsInCart
