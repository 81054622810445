import React, { useEffect } from "react"
import styled from "styled-components"

import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"

import Button from "components/button"
import SidebarContent from "components/sidebarContent"
import Spacer from "components/spacer"
import Textarea from "components/textarea"

import * as theme from "theme"
import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"
import { addToCart, handleAmountChange, handleMaximumItems, itemsInCart, itemsInSection } from "./"

const Order = ({ introduction, uri, sections, cart, setCart, setIsConfirmingOrder, ...rest }) => {
  const [, dispatch] = useStore()

  useEffect(() => {
    // enforce maximum items on cart update
    handleMaximumItems(cart, setCart, sections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const sidebar = (
    <Cart>
      <CartTitle>Cart</CartTitle>
      <MyOrder>
        {!!cart && cart?.length ? (
          cart.map((item, cartIndex) => {
            const inputProps = { min: "0", step: "1" }

            if (item.maxPerItem) {
              inputProps.max = item.maxPerItem
            }

            return (
              <CartItem key={cartIndex}>
                <Counter
                  type="number"
                  inputProps={inputProps}
                  value={item.amount}
                  onChange={handleAmountChange(item, cart, setCart)}
                />
                <CartItemTitle component="h3" variant="h6">
                  {item.name}
                </CartItemTitle>
              </CartItem>
            )
          })
        ) : (
          <Typography>No items selected</Typography>
        )}
      </MyOrder>

      {!!cart?.length && (
        <Spacer pl={20} pb={20} pr={20}>
          <StyledButton fullWidth onClick={() => setIsConfirmingOrder(true)}>
            Checkout
          </StyledButton>
        </Spacer>
      )}
    </Cart>
  )

  return (
    <SidebarContent
      hideHeader
      image={null}
      title={"Order Form"}
      slug={getUrlPath(uri)}
      sidebar={sidebar}
      edgesSize={"none"}
      reverseOrderForMobile
      position={"right"}
    >
      {introduction && <Textarea content={introduction} />}
      <Inner {...rest}>
        <Sections>
          {!!sections &&
            sections.map(({ headline, items, max, maxPerItem }, sectionIndex) => (
              <Section key={sectionIndex}>
                <Typography
                  component="h2"
                  variant="h3"
                  gutterBottom
                  id={headline && headline.split(" ").join("-").toLowerCase()}
                >
                  {headline}
                </Typography>

                {!!max && (
                  <Typography variant="h4" gutterBottom>
                    Choose up to {max} {headline}.
                  </Typography>
                )}

                <Items>
                  {!!items &&
                    items.map((item, itemIndex) => (
                      <Item key={itemIndex}>
                        <div>
                          <ImageContainer
                            onClick={() =>
                              dispatch({
                                type: "SET_LIGHTBOX",
                                payload: {
                                  open: true,
                                  slide: 0,
                                  slides: [
                                    {
                                      link: null,
                                      image: item.image.sourceUrl
                                    }
                                  ],
                                  options: {
                                    thumbs: false
                                  }
                                }
                              })
                            }
                          >
                            {item?.image?.sourceUrl && <img src={item.image.sourceUrl} alt={item.name} />}
                          </ImageContainer>

                          <ItemTitle component="h3" variant="h6" gutterBottom>
                            {item.name}
                          </ItemTitle>
                        </div>

                        <CartButton
                          disabled={
                            (!!maxPerItem && itemsInCart(item.name, cart) >= maxPerItem) ||
                            (!!max && itemsInSection(cart, sectionIndex) >= max)
                          }
                          onClick={() => addToCart(item.name, max, maxPerItem, sectionIndex, cart, setCart)}
                        >
                          Add to Cart
                        </CartButton>
                      </Item>
                    ))}

                  <Item style={{ height: 0 }} />
                  <Item style={{ height: 0 }} />
                </Items>
              </Section>
            ))}
        </Sections>
      </Inner>
    </SidebarContent>
  )
}

const CartTitle = styled(Typography)`
  display: block;
  padding: 15px 20px 0;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
  color: ${theme.colors.primary};
  text-transform: uppercase;
`

const Inner = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`

const Item = styled.div`
  width: 100%;
  margin-bottom: 30px;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 500px) {
    width: calc(50% - 10px);
  }
`
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  cursor: pointer;

  img {
    max-height: 100%;
    max-width: calc(100% - 20px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;
  }
`

const CartButton = styled(Button)`
  && {
    padding: 10px 20px;
  }
`

const Items = styled.div`
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ItemTitle = styled(Typography)`
  display: flex;
  align-items: flex-start;
`
const Cart = styled.div`
  overflow: auto;
  max-height: calc(100vh - 64px);
`

const MyOrder = styled.div`
  padding: 20px;
`
const Sections = styled.div``
const Section = styled.section`
  margin-bottom: 40px;
`
const CartItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CartItemTitle = styled(Typography)`
  width: calc(100% - 65px);
  font-size: 16px;
`
const Counter = styled(TextField)`
  width: 55px;
  background: #e6e6e6;

  .MuiInputBase-input {
    padding: 6px 4px;
  }
`
const StyledButton = styled(Button)`
  border: 1px solid ${theme.colors.primary};
`

export default Order
