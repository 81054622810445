import produce from "immer"
import { set } from "lodash"

const handleAmountChange = (item, cart, setCart) => (event) => {
  const existingItemIndex = cart.findIndex(({ name }) => name === item.name)
  const itemExists = existingItemIndex > -1

  if (itemExists) {
    const newCart = produce(cart, (draft) => {
      set(draft, `${existingItemIndex}.amount`, event.target.value)
    })

    setCart(newCart)
  }
}

export default handleAmountChange
