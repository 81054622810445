import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Image } from "react-image-and-background-image-fade"

// import material ui
import { Dialog, CircularProgress, Tooltip, IconButton } from "@material-ui/core"
import { Close, Download } from "mdi-material-ui"

import { imageServices } from "services"

const GalleryDialog = props => {
  const { image, onClose } = props

  const [activeImage, setActiveImage] = useState(null)

  useEffect(() => {
    if (image) {
      loadImage()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  const loadImage = async () => {
    const result = await imageServices.loadImageDetails(image.public_id)

    if (result) {
      setActiveImage(result)
    }
  }

  const downloadImage = () => {
    fetch(activeImage.secure_url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "image.png") //or any other extension
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const calcDimensions = () => {
    const width = activeImage.width
    const height = activeImage.height
    const ratio = width / height

    let newWidth, newHeigth

    if (width > height) {
      newWidth = 900
      newHeigth = newWidth / ratio
    } else {
      newHeigth = 900
      newWidth = newHeigth * ratio
    }

    return { width: newWidth, height: newHeigth }
  }

  return (
    <Dialog open={true} onClose={onClose} fullScreen>
      <Container>
        {activeImage ? (
          <ImageContainer width={`${calcDimensions().width}px`} height={`${calcDimensions().height}px`}>
            <Image
              src={activeImage.secure_url}
              width={`${activeImage.width}px`}
              height={`${activeImage.height}px`}
              isResponsive
            />
          </ImageContainer>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Container>

      {activeImage && (
        <DownloadContainer>
          <Tooltip title="Download">
            <IconButton onClick={downloadImage}>
              <Download />
            </IconButton>
          </Tooltip>
        </DownloadContainer>
      )}

      <CloseContainer>
        <Tooltip title="Close">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </CloseContainer>
    </Dialog>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
`

const ImageContainer = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};

  img {
    max-height: 100%;
    width: auto;
  }
`

const CloseContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;

  svg {
    path {
      fill: #fff;
    }
  }
`

const DownloadContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;

  svg {
    path {
      fill: #fff;
    }
  }
`

export default GalleryDialog
