import React, { useState, Fragment } from "react"
import styled, { css } from "styled-components"
import { ArrowDown } from "react-ionicons"
import Typography from "@material-ui/core/Typography"
import Collapse from "@material-ui/core/Collapse"
import Textarea from "components/textarea"
import Button from "components/button"
import * as theme from "theme"

const Accordion = ({ headline, readMoreLabel, items, ...rest }) => {
  const initialState =
    items?.reduce((acc, _, index) => {
      acc[index] = false
      return acc
    }, {}) || {}

  const [openItems, setOpenItems] = useState(initialState)
  const toggleItem = (index) => {
    setOpenItems({ ...openItems, [index]: !openItems[index] })
  }

  return (
    <Container {...rest}>
      {!!headline && (
        <Typography variant="h2" gutterBottom>
          {headline}
        </Typography>
      )}
      {!!items &&
        items.map((item, index) => {
          return (
            <Fragment key={index}>
              <Item isIn={openItems[index]}>
                <Typography variant="h3" gutterBottom>
                  {item.title}
                </Typography>

                <Typography variant="h4" gutterBottom>
                  {item.subtitle}
                </Typography>

                <Textarea content={item.text} />

                {!!item?.hiddenText && (
                  <MoreContainer>
                    <Button onClick={() => toggleItem(index)} className="button" fullWidth>
                      {readMoreLabel || "Read More"}
                      <ArrowDown />
                    </Button>
                    <Collapse in={openItems[index]}>
                      <Textarea className="hidden-text" content={item.hiddenText}></Textarea>
                    </Collapse>
                  </MoreContainer>
                )}
              </Item>
            </Fragment>
          )
        })}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${theme.colors.lightgrey};

  ${({ isIn }) =>
    !!isIn &&
    css`
      .button svg {
        transform: rotate(-180deg);
      }
    `}

  .hidden-text {
    padding: 20px;
  }
  .button {
    &:hover {
      svg {
        fill: white;
      }
    }

    svg {
      fill: ${theme.colors.primary};
      margin-left: 10px;
      transform-origin: center;
      transition: transform 180ms ease-in-out;
    }
  }
`

const MoreContainer = styled.div`
  background: ${theme.colors.lightgrey};
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: center; */
`

export default Accordion
