import React from "react"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Typography from "@material-ui/core/Typography"
import LinkIcon from "../../../static/images/icons/link.svg"
import DownloadIcon from "../../../static/images/icons/download.svg"

import isUrlExternal from "../../utils/isUrlExternal"
import * as theme from "theme"
import { getUrlPath } from "utils/getUrlPath"

export default function Downloads(props) {
  const { headline, downloads } = props

  const handleClick = (element) => {
    if (process.env.GATSBY_GOOGLE_ANALYTICS) {
      trackCustomEvent({
        category: "Download",
        action: "Click",
        label: element.title,
        value: 1
      })
    }
  }

  return (
    <Container>
      {!!headline && (
        <Typography variant="h2" gutterBottom>
          {headline}
        </Typography>
      )}

      <Buttons>
        {!!downloads &&
          downloads.map((element, index) => {
            const { title, category, download, link } = element

            {
              /* const targetBlank = (category === "download" || isUrlExternal(link?.url)) && {
              target: "_blank",
            } */
            }

            return (
              <a
                key={index}
                href={category === "download" ? download?.localFile?.publicURL : link?.url}
                onClick={() => handleClick(element)}
                className="download"
                target='_blank'
                rel='noopener noreferrer'
              >
                <StyledButton>
                  <BtnTypography color="inherit">
                    {category === "download" ? <DownloadIcon /> : <LinkIcon />}
                    {title}
                  </BtnTypography>
                </StyledButton>
              </a>
            )
          })}
      </Buttons>
    </Container>
  )
}

const Container = styled.div`
  padding: 30px 0;
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  a {
    width: 100%;
    cursor: pointer;
    margin-bottom: 20px;

    @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
      width: calc(50% - 10px);
    }
  }
`
const StyledButton = styled.button`
  cursor: pointer;
  background: #2c312c;
  border: 1px solid #2c312c;
  color: white;
  justify-content: center;
  align-items: center;
  transition: ease all 0.4s;
  padding: 10px 20px 10px 20px;
  width: 100%;

  &:hover {
    background: none;
    color: black;

    svg {
      path {
        fill: black;
      }
    }
  }
`
const BtnTypography = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: left;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }
`
