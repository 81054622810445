import React from "react"
import styled from "styled-components"

// import app components
import Wysiwyg from "../textarea"
import BackgroundImage from "../backgroundImage"
import PlayIcon from "../../../static/images/icons/play.svg"

import * as theme from "theme"
import { useStore } from "store"

export default function TextareaVideo(props) {
  const { link, text, image } = props
  const dispatch = useStore()[1]

  const showLightBox = () => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: 0,
        slides: [{ link, image }],
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container>
      <Content>
        <Wysiwyg content={text} />
      </Content>
      <Image onClick={() => showLightBox()}>
        {link?.url.includes("youtu") && <PlayIcon />}
        {image && <BackgroundImage position="relative" image={image} maxWidth={400} />}
      </Image>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 30px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    margin-bottom: 4vw;
  }

  @media screen and (max-width: ${theme.mui.breakpoints.values.md}px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const Content = styled.div``

const Image = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${theme.colors.lightgrey};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: 100%;
    height: 170px;
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: 300px;
    margin-left: 20px;
  }

  svg {
    height: 55px;
    filter: drop-shadow(2px 4px 6px black);
    opacity: 0.95;
    position: relative;
    z-index: 1;
    width: 55px;
  }
`
